<template>
	<div>
		<sb-operatip>当月剩余可免费使用<span class="primary">15</span>次，超出部分<span class="primary">0.5</span>元/次。</sb-operatip>
		<div class="tabBox">
			<sb-tab @beforeLeave="beforeLeave" ref="rankTab" :list="rankTabList">
			</sb-tab>
		</div>
		<div>
			<sb-explain>
				<div>
					是的发生的发送到发送到
				</div>
			</sb-explain>
		</div>

	</div>
</template>

<script>
	import SbOperatip from '../../../components/opera_tip/opera_tip.vue';
	import SbTab from '../../../components/tab/tab.vue';
	import SbExplain from '../../../components/explain/explain.vue';
	export default {
		data() {
			return {
				rankTabList: [{
						name: '排名查询',
						id: '0',
					},
					{
						name: '查询记录',
						id: '1',
					}
				]
			}
		},
		methods: {
			beforeLeave({
				news,
				olds
			}) {
				console.log(news, olds)
			}
		},
		components: {
			SbOperatip,
			SbTab,
			SbExplain,
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	
	.tabBox {
		padding: 0 20px;
	}
</style>
